import type { ICart } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

interface IStep {
  index: number
  title: string
  percent: number
  progress: boolean
  showTitle: boolean
}

const STEPS: IStep[] = [
  {
    index: 0,
    title: 'Resumo do pedido',
    percent: 0,
    progress: false,
    showTitle: true,
  },
  {
    index: 1,
    title: 'Dados pessoais',
    percent: 17,
    progress: true,
    showTitle: true,
  },
  {
    index: 2,
    title: 'Dados pessoais',
    percent: 17,
    progress: true,
    showTitle: true,
  },
  {
    index: 3,
    title: 'Disponibilidade de Horário',
    percent: 34,
    progress: true,
    showTitle: true,
  },
  {
    index: 4,
    title: 'Endereço de cobrança',
    percent: 51,
    progress: true,
    showTitle: true,
  },
  {
    index: 5,
    title: 'Pedido Médico',
    percent: 51,
    progress: true,
    showTitle: true,
  },
  {
    index: 6,
    title: 'Forma de Pagamento',
    percent: 68,
    progress: true,
    showTitle: true,
  },
  {
    index: 7,
    title: 'Pedido Realizado',
    percent: 100,
    progress: false,
    showTitle: false,
  },
  {
    index: 8,
    title: '',
    percent: 100,
    progress: false,
    showTitle: false,
  },
]

export default () => {
  const step = useState<IStep>('CartStep', () => STEPS[0])

  return {
    step,
    setStep(index: number) {
      step.value = STEPS[index]
    },
    steps: computed(() => {
      const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)

      if (!cart.value || !Object.keys(cart.value).length)
        return []

      const steps = [
        { idx: 0, title: 'Selecionou', msg: 'Selecionou seus exames', icon: 'bi:hand-index-thumb' },
        { idx: 1, title: 'Comparou', msg: 'Comparou os orçamentos', icon: 'material-symbols:compare-arrows-rounded' },
        { idx: 2, title: 'Comprou', msg: 'Comprou seus exames', icon: 'tabler:shopping-cart-check' },
        { idx: 3, title: 'Agendar', msg: 'Agendar com Concierge', icon: 'material-symbols-light:calendar-clock-outline-rounded' },
        { idx: 4, title: 'Realizar', msg: 'Realizar exame', icon: 'material-symbols:lab-research-outline' },
      ]

      const needSchedule = cart.value.cartDetails.find(({ needSchedule }) => needSchedule)

      return needSchedule ? steps : steps.filter(({ idx }) => idx !== 3)
    }),
  }
}
